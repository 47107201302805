<template>
  <v-container>
    <!-- ############# -->
    <v-layout row wrap justify-space-between class="mb-2">
      <v-flex xs6 sm4 md4 lg4 class="mb-1">
        <v-btn
          :color="$store.state.secondaryColor"
          outlined
          small
          @click="goBack"
        >
          <v-icon small left>mdi-arrow-left</v-icon>
          <span class="caption">{{ $t("back") }}</span>
        </v-btn>
      </v-flex>
      <v-flex xs6 sm4 md4 lg4 class="mb-1 text-right">
        <v-btn
          small
          outlined
          class="mr-3"
          @click="updateAccount($route.params.id)"
          :color="$store.state.primaryColor"
        >
          {{ $t("edit") }}
          <v-icon small right>edit</v-icon>
        </v-btn>
        <v-btn
          small
          outlined
          class="mr-3"
          @click="delDialog = true"
          :color="$store.state.secondaryColor"
        >
          {{ $t("delete") }}
          <v-icon small right>delete</v-icon>
        </v-btn>
      </v-flex>
    </v-layout>
    <!-- ################ -->

    <v-dialog v-model="delDialog" persistent max-width="290">
      <v-card>
        <div class="text-center">
          <v-btn class="my-5" outlined large fab color="red darken-4">
            <v-icon>warning</v-icon>
          </v-btn>
        </div>

        <v-card-text class="text-center">
          <!-- <v-icon>delete</v-icon> -->
          <p>{{ $t("are you sure") }}?</p>
          <p>{{ $t("do you really want to delete") }} {{ supplier.name }}?</p>
        </v-card-text>
        <v-card-actions>
          <v-btn
            :color="$store.state.secondaryColor"
            text
            small
            @click="delDialog = false"
          >
            {{ $t("cancel") }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            :color="$store.state.primaryColor"
            text
            small
            @click="deleteSupplier"
          >
            {{ $t("confirm") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      shaped
      top
      rounded="pill"
      v-model="snackbar"
      :timeout="timeout"
      color="blue-grey"
    >
      {{ $t("deleted") }}!

      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <div v-if="pleaseWait" class="mt-3">
      <PleaseWait></PleaseWait>
    </div>
    <div v-else>
      <v-layout row wrap justify-start v-if="supplier" class="mt-2">
        <v-flex xs12 sm12 md12 lg12>
          <span class="text-h4 font-weight-black">{{
            supplier.accountName
          }}</span>
        </v-flex>
      </v-layout>
      <v-layout row wrap justify="center" align="center">
        <v-flex class="pa-5 mx-1 elevation-2" style="">
          <v-list dense class="elevation-1">
            <v-list-item class="body-1 d-flex flex-wrap">
              <v-col cols="12" md="4">
                <span class="font-weight-black text-capitalize"
                  >{{ $t("Account name") }}:
                </span>
                <span class="">{{ supplier.accountName }} </span>
              </v-col>
              <v-col cols="12" md="4">
                <span class="font-weight-black text-capitalize"
                  >{{ $t("Account type") }}:
                </span>
                <span class="">{{ supplier.accountType }} </span>
              </v-col>
              <v-col cols="12" md="4">
                <span class="font-weight-black text-capitalize"
                  >{{ $t("amount") }}:
                </span>
                <span class="">{{ supplier.amount }} </span>
              </v-col>
            </v-list-item>

            <v-divider></v-divider>

            <v-list-item class="body-1 d-flex flex-wrap">
              <v-col cols="12" md="4">
                <span class="font-weight-black text-capitalize"
                  >{{ $t("Business") }}:
                </span>
                <span class="">{{ supplier.businessId }} </span>
              </v-col>
              <v-col cols="12" md="4">
                <span class="font-weight-black text-capitalize"
                  >{{ $t("Date time") }} :
                </span>
                <span class="">{{ supplier.dateTime }} </span>
              </v-col>
            </v-list-item>
            <v-list-item class="body-1 d-flex flex-wrap">
              <v-col cols="12" md="12"
                ><span class="font-weight-black text-capitalize"
                  >{{ $t("particulars") }}:
                </span>
                <v-textarea
                  outlined
                  disabled
                  class="mt-3"
                  name="input-7-4"
                  :label="$t('particulars')"
                  :value="supplier.particulars"
                ></v-textarea>
              </v-col>
            </v-list-item>
          </v-list>
        </v-flex>
      </v-layout>
    </div>
  </v-container>
</template>

<script>
import db from "@/plugins/fb";
import PleaseWait from "@/components/templates/PleaseWait";

export default {
  components: {
    PleaseWait,
  },
  data: () => ({
    supplier: "",
    pleaseWait: true,
    delDialog: false,
    snackbar: false,
    timeout: 2000,
  }),

  created() {
    this.getSupplierProfile();
  },

  methods: {
    getSupplierProfile() {
      db.collection("accounts")
        .doc(this.$route.params.id)
        .get()
        .then((doc) => {
          this.supplier = doc.data();

          this.pleaseWait = false;
        })
        .catch((error) => {
          console.log("Error getting  document:", error);
          this.snackError = true;
          this.pleaseWait = false;
        });
    },

    updateAccount(supId) {
      this.$router.push({ name: "UpdateAccount", params: { id: supId } });
    },

    deleteSupplier() {
      db.collection("suppliers")
        .doc(this.$route.params.id)
        .delete()
        .then(() => {
          this.snackbar = true;
          setTimeout(() => this.goBack(), 2000);
        })
        .catch((error) => {
          console.error("Error removing document: ", error);
        });
    },

    goBack() {
      window.history.back();
    },
  },
};
</script>

<style></style>
